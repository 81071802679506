#g-app-header-container
#g-global-nav-container
#g-app-body-container.g-default-layout
#g-app-footer-container

#g-app-progress-container

//- Set tabindex on modal element to allow closing modal by pressing Escape. See:
//- - http://getbootstrap.com/javascript/#modals-examples
//- - http://stackoverflow.com/questions/12630156/
#g-dialog-container.modal.fade(tabindex="-1")
#g-alerts-container
