ul.g-admin-options
  li
    a.g-server-config(href="#settings")
      i.icon-cog-alt
      |  Server configuration
  li
    a.g-plugins-config(href="#plugins")
      i.icon-puzzle
      |  Plugins
  li
    a.g-assetstore-config(href="#assetstores")
      i.icon-box-1
      |  Assetstores
