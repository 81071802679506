include accessEditorMixins

if !hidePrivacyEditor
  +privacyEditor(modelType)
+permissionsEditor

if !hideRecurseOption
  +recurseOption(modelType)

if !hideSaveButton
  +saveAccessButton
