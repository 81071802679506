.g-search-results-header-container
  .g-search-results-title-container
    i.g-search-results-title-element.icon-search
    span.g-search-results-title.g-search-results-title-element Results : #{query}
  .g-search-results-header
    .g-search-results-advance-search

.g-search-results-container

.g-search-pending
  i.icon-spin4.animate-spin

.g-search-no-results(style='display: none;')
  i.icon-block
  |  No results found
