.modal-dialog
  .modal-content
    form#g-folder-edit-form.modal-form(role="form")
      .modal-header
        button.close(data-dismiss="modal", aria-hidden="true", type="button") &times;
        h4.modal-title
          if (folder)
            | Edit folder
          else
            | Create folder
      .modal-body
        .form-group
          label.control-label(for="g-name") Name
          input#g-name.input-sm.form-control(type="text", placeholder="Enter folder name")
        .form-group
          label.control-label Description (optional)
          .g-description-editor-container
        .g-validation-failed-message
      .modal-footer
        a.btn.btn-small.btn-default(data-dismiss="modal") Cancel
        button.g-save-folder.btn.btn-small.btn-primary(type="submit")
          if (folder)
            i.icon-edit
            | Save
          else
            i.icon-plus-squared
            | Create
