.g-task-progress-top
  .g-task-progress-bar.progress(class=progressClass)
    .progress-bar(class=barClass, style=`width: ${width};`)
      .progress-status
        .progress-percent
          = percentText
        .progress-left
          = timeLeftText
  .g-task-progress-title(title=progress.data.title)
    if progress.data.resource && progress.data.resource._id && progress.data.resourceName
      a(title=progress.data.title, href='#' + progress.data.resourceName + '/' + progress.data.resource._id)
        = progress.data.title
    else
      = progress.data.title
.g-task-progress-bottom
  .g-task-progress-message= progress.data.message
