.g-body-title System configuration
form.g-settings-form(role="form")
  .g-settings-form-container
    h4 Instance Branding
    .form-group
      label(for="g-core-brand-name") Brand Name
      input#g-core-brand-name.form-control.input-sm(
          type="text", value=settings['core.brand_name'] || '',
          title="The brand name of the application.")
    .form-group
      label(for="g-core-contact-email-address") Contact email address
      input#g-core-contact-email-address.form-control.input-sm(
          type="text", value=settings['core.contact_email_address'] || '',
          title="The contact email address for users to obtain support with this instance.")
    .form-group
      label(for="g-core-banner-color") Banner Color
      #g-core-banner-color-container
        input#g-core-banner-color.form-control.input-sm(
            type="color", value=settings['core.banner_color'],
            title="The banner color of the application.")
        button#g-core-banner-default-color.form-control.input-sm(
            type="button", title="Default color") default
  .form-group
    label(for="g-core-privacy-notice") Privacy Notice Link
    input#g-core-privacy-notice.form-control.input-sm(
        type="text", value=settings['core.privacy_notice'] || '',
        title="The privacy notice link.")
  .form-group
    label(for="g-core-cookie-lifetime") Cookie duration (days)
    input#g-core-cookie-lifetime.form-control.input-sm(
        type="text", value=settings['core.cookie_lifetime'] || '',
        title="How long users will stay logged in.")
  .g-settings-form-container
    h4 Administrative Policy
    .form-group
      label(for="g-core-registration-policy") Registration policy
      select#g-core-registration-policy.form-control.input-sm
        option(value="open",
            selected=(settings['core.registration_policy'] === "open")) Open registration
        option(value="closed",
            selected=(settings['core.registration_policy'] === "closed")) Closed registration
        option(value="approve",
            selected=(settings['core.registration_policy'] === "approve")) Admin approval required
    .form-group
      label(for="g-core-email-verification") Email verification
      select#g-core-email-verification.form-control.input-sm
        option(value="required",
            selected=(settings['core.email_verification'] === "required")) Required
        option(value="optional",
            selected=(settings['core.email_verification'] === "optional")) Optional
        option(value="disabled",
            selected=(settings['core.email_verification'] === "disabled")) Disabled
    .form-group
      label(for="g-core-add-to-group-policy") Allow members to be directly added to groups
      select#g-core-add-to-group-policy.form-control.input-sm
        option(value="never",
            selected=(settings['core.add_to_group_policy'] === "never"))
          | No, members must be invited and accept invitations
        option(value="noadmin",
            selected=(settings['core.add_to_group_policy'] === "noadmin"))
          | No, except for group administrators when enabled per group
        option(value="nomod",
            selected=(settings['core.add_to_group_policy'] === "nomod"))
          | No, except for group administrators and moderators when enabled per group
        option(value="yesadmin",
            selected=(settings['core.add_to_group_policy'] === "yesadmin"))
          | Yes, by group administrators unless disabled per group
        option(value="yesmod",
            selected=(settings['core.add_to_group_policy'] === "yesmod"))
          | Yes, by group administrators and moderators unless disabled per group
    .g-collection-create-policy-container
      label Collection creation policy
      p.
        Allow additional users and groups to create collections.
      input.g-setting-switch(type="checkbox",
          checked="checked", data-size="mini", data-on-color="primary")
      .access-widget-container
      textarea#g-core-collection-create-policy.hide
        = JSON.stringify(settings['core.collection_create_policy'])
    .form-group
      label(for="g-core-user-default-folders") Create default folders for new users
      select#g-core-user-default-folders.form-control.input-sm
        option(value="none",
            selected=(settings['core.user_default_folders'] === "none")) No
        option(value="public_private",
            selected=(settings['core.user_default_folders'] === "public_private")) Yes, "Public" and "Private"
    .form-group
      .checkbox
        label(for="g-core-api-keys")
          input#g-core-api-keys(type="checkbox", checked=settings['core.api_keys'])
          | Enable API keys
    .form-group
      .checkbox
        label(for="g-core-enable-password-login")
          input#g-core-enable-password-login(
              type="checkbox", checked=settings['core.enable_password_login'])
          | Enable password login
  .g-settings-form-container
    h4 Email Creation
    .form-group
      label(for="g-core-email-from-address") Email from address
      input#g-core-email-from-address.form-control.input-sm(
          type="text", value=settings['core.email_from_address'] || '',
          title="The email address the system will use when sending emails.")
    .form-group
      label(for="g-core-email-host") Email host
      input#g-core-email-host.form-control.input-sm(
          type="text", value=settings['core.email_host'] || '',
          title="The host email server that is shown as a link in the footer of emails.")
  .g-settings-form-container
    h4 Email Delivery
    .form-group
      label(for="g-core-smtp-host") SMTP host
      input#g-core-smtp-host.form-control.input-sm(
          type="text", value=settings['core.smtp_host'] || '',
          title="The hostname of the SMTP server used to send emails.")
    .form-group
      label(for="g-core-smtp-port") SMTP port
      input#g-core-smtp-port.form-control.input-sm(
          type="text", value=settings['core.smtp.port'] || '',
          title="The port number of the SMTP server used to send emails.")
    .form-group
      label(for="g-core-smtp-encryption") SMTP encryption
      select#g-core-smtp-encryption.form-control.input-sm
        option(value="none",
            selected=(settings['core.smtp.encryption'] === "none")) None
        option(value="starttls",
            selected=(settings['core.smtp.encryption'] === "starttls")) Use STARTTLS
        option(value="ssl",
            selected=(settings['core.smtp.encryption'] === "ssl")) Use SSL/TLS
    .form-group
      label(for="g-core-smtp-username") SMTP username
      input#g-core-smtp-username.form-control.input-sm(
          type="text", value=settings['core.smtp.username'] || '',
          title="The username, if any, used to authenticate to the SMTP server used to send emails.")
    .form-group
      label(for="g-core-smtp-password") SMTP password
      input#g-core-smtp-password.form-control.input-sm(
          type="password", value=settings['core.smtp.password'] || '',
          title="The password, if any, used to authenticate to the SMTP server used to send emails.")

  #g-configuration-accordion.panel-group
    .panel.panel-default
      .panel-heading(data-toggle="collapse",
          data-parent="#g-configuration-accordion",
          data-target="#g-advanced-settings-tab")
        .panel-title
          a
            b Advanced Settings
      #g-advanced-settings-tab.panel-collapse.collapse
        .panel-body
          p These settings should only be changed if you are certain of what you are doing.
          .form-group
            label(for="g-core-server-root") Server root URL
            input#g-core-server-root.form-control.input-sm(
                type="text", value=settings['core.server_root'] || '',
                placeholder='Detect automatically')
          .form-group
            label(for="g-core-upload-minimum-chunk-size") Upload minimum chunk size (bytes)
            br
            span This applies to Filesystem assetstores.
            input#g-core-upload-minimum-chunk-size.form-control.input-sm(
                type="text", value=settings['core.upload_minimum_chunk_size'] || '',
                title="For large files, the minimum size of all but the last chunk.")
          .form-group
            label Server Notification Stream
            br
            span Disabling this may improve server performance with many concurrent web client users.
            .checkbox
              label(for="g-core-enable-notification-stream")
                input#g-core-enable-notification-stream(
                type="checkbox", checked=(settings['core.enable_notification_stream']))
                | Enabled
          .g-settings-form-container
            h4 CORS
            p.
              CORS (Cross-origin resource sharing) allows access from other websites.
              These settings are described in
              detail #[a(href="http://girder.readthedocs.org/en/latest/security.html#cors-cross-origin-resource-sharing", target="_blank") here].
            .form-group
              label(for="g-core-cors-allow-origin") CORS Allowed Origins
              input#g-core-cors-allow-origin.form-control.input-sm(
                  type="text", value=settings['core.cors.allow_origin'] || '',
                  title="A comma-separated list of allowed base URLs that can make cross-site requests. This may include *.")
            .form-group
              label(for="g-core-cors-allow-methods") CORS Allowed Methods
              input#g-core-cors-allow-methods.form-control.input-sm(
                  type="text", value=settings['core.cors.allow_methods'] || '',
                  placeholder="Default: allow all methods",
                  title="A comma-separated list of allowed methods")
            .form-group
              label(for="g-core-cors-allow-headers") CORS Allowed Headers
              input#g-core-cors-allow-headers.form-control.input-sm(
                  type="text", value=settings['core.cors.allow_headers'] || '',
                  title="A comma-separated list of allowed headers.")
  p
  .form-group
    button.g-submit-settings.btn.btn-default.btn-sm #[i.icon-ok]  Save
  #g-settings-error-message.g-validation-failed-message
