.modal-dialog
  .modal-content
    form#g-login-form.modal-form(role="form")
      .modal-header
        button.close(data-dismiss="modal", aria-hidden="true", type="button") &times;
        h4.modal-title Log in
      .modal-body
        if enablePasswordLogin
          .form-group
            label.control-label(for="g-login") Login or email
            input#g-login.input-sm.form-control(type="text", placeholder="Enter login")
          .form-group
            label.control-label(for="g-password") Password
            input#g-password.input-sm.form-control(type="password", placeholder="Enter password")
          #g-login-otp-group.form-group.hidden
            label.control-label(for="g-login-otp") Two-factor code
            input#g-login-otp.input-sm.form-control(
              type='text', minlength=6, maxlength=6, placeholder="Your 6-digit two-factor authentication code, if enabled")
          .g-validation-failed-message
          .g-bottom-message
            if registrationPolicy !== 'closed'
              | Don't have an account yet?
              a.g-register-link  Register here.
              |  |
            a.g-forgot-password  Forgot your password?
      .modal-footer
        a.btn.btn-default(data-dismiss="modal") Close
        button#g-login-button.btn.btn-primary(type="submit")
          i.icon-login
          |  Login
