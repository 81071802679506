.g-plugin-config-breadcrumbs
  ol.breadcrumb
    li
      a.g-admin-console-link
        i.icon-wrench
        | Admin console
    li
      a.g-plugins-link
        i.icon-puzzle
        | Plugins
    li.active
      i.icon-cog
      | #{pluginName}
