.modal-dialog
  .modal-content
    form#g-reset-password-form.modal-form(role="form")
      .modal-header
        button.close(data-dismiss="modal", aria-hidden="true", type="button") &times;
        h4.modal-title Forgotten password
      .modal-body
        .g-password-reset-explanation
          | If you have forgotten your password, enter your email address here.
          | You will be sent an email with a temporary access link, from
          | which you can reset your password.
        .form-group
          label.control-label(for="g-email") Email
          input#g-email.input-sm.form-control(type="text", placeholder="Enter your email")
        .g-validation-failed-message
        .g-bottom-message
          if registrationPolicy !== 'closed'
            a.g-register-link  Register
            |  |
          a.g-login-link  Login
      .modal-footer
        a.btn.btn-default(data-dismiss="modal") Close
        button#g-reset-password-button.btn.btn-primary(type="submit")
          i.icon-mail
          |  Submit
