.g-search-result-type-header-container
  i.g-search-result-type-header.g-search-result-type-icon(class=`icon-${icon}`)
  .g-search-result-type-header.g-search-results-type-title
    | #{collectionName}
ul.g-search-results-type
  each element in results
    li.g-search-result
      a(href=`#${type}/${element._id}`)
        if type === 'user'
          | #{element.firstName} #{element.lastName} (#{element.login})
        else
          | #{element.name}
.g-search-results-paginate(id=`${type}Paginate`)
